import React, {memo, useCallback, useMemo} from 'react';
import {
  Box,
  Icon,
  Radio,
  Table,
  Paper,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TextField,
  Typography,
  FormControlLabel,
  Grid,
  useTheme,
} from '@mui/material';
import {
  SentimentSatisfied,
  SentimentDissatisfied,
  SentimentSatisfiedAlt,
  SentimentVerySatisfied,
  SentimentVeryDissatisfied,
} from '@mui/icons-material';
import {Theme} from '@mui/material/styles';
import {makeStyles} from '@mui/styles';
import clsx from 'clsx';
import {IOptions} from 'shared/services/api/student-quiz/QuestionList';

const useStyles = makeStyles((theme: Theme) => ({
  borderRadiusLeft: {
    borderTopLeftRadius: theme.spacing(0.5),
    borderBottomLeftRadius: theme.spacing(0.5),
  },
  borderRadiusRight: {
    borderTopRightRadius: theme.spacing(0.5),
    borderBottomRightRadius: theme.spacing(0.5),
  },
  tableCell: {
    backgroundColor: theme.palette.grey[200],
    paddingTop: 0,
    paddingBottom: 0,
  },
  tableRow: {
    borderBottomStyle: 'solid',
    borderBottomWidth: theme.spacing(1),
    borderBottomColor: theme.palette.background.default,
  },
  cellFixed: {
    whiteSpace: 'nowrap',
    position: 'sticky',
    left: 0,
    zIndex: 1,
    border: 0,
  },
  shadow: {
    boxShadow: `inset -1px 0px 0px ${theme.palette.grey[300]}`,
  },
}));

interface IGridUniqueAnswerPreviewProps {
  options: IOptions[];
  comment: string;
  hasComment?: boolean;
  isCompleted: boolean;
  hasError: boolean;
  setComment: (comment: string) => void;
  onChange: (options: IOptions[]) => void;
}

export const GridUniqueAnswerPreview: React.FC<IGridUniqueAnswerPreviewProps> =
  memo(
    ({
      options,
      comment,
      hasComment = false,
      isCompleted,
      hasError,
      setComment,
      onChange,
    }) => {
      const styles = useStyles();
      const theme = useTheme();

      const handleOption = useCallback(
        (rowPosition: number, columnPosition: number) => {
          const optionsCopy = options;

          optionsCopy[rowPosition].opcoesRespostaColuna.map(
            (optionColumn) => (optionColumn.selecionado = false),
          );

          optionsCopy[rowPosition].selecionado = true;
          optionsCopy[rowPosition].opcoesRespostaColuna[
            columnPosition
          ].selecionado = true;

          onChange(optionsCopy);
        },
        [onChange, options],
      );

      const handleComment = (e: React.ChangeEvent<HTMLInputElement>) => {
        setComment(e.target.value);
      };

      const renderEmoji = useCallback((position: string) => {
        switch (position) {
          case '0':
            return (
              <SentimentVeryDissatisfied color="primary" fontSize={'large'} />
            );

          case '1':
            return <SentimentDissatisfied color="primary" fontSize={'large'} />;

          case '2':
            return <SentimentSatisfied color="primary" fontSize={'large'} />;

          case '3':
            return <SentimentSatisfiedAlt color="primary" fontSize={'large'} />;

          case '4':
            return (
              <SentimentVerySatisfied color="primary" fontSize={'large'} />
            );

          default:
            return <Icon color="primary" fontSize={'large'} />;
        }
      }, []);

      const useEmoji = useMemo(() => {
        const regexHexCode = /[0-9A-Fa-f]{6}/g;
        return regexHexCode.test(options[0].opcoesRespostaColuna[1].iconeCor)
          ? false
          : true;
      }, [options]);

      return (
        <>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box
              width={'100%'}
              display={'table'}
              style={{tableLayout: 'fixed'}}>
              <Box width={'100%'} display={'table-cell'} overflow={'auto'}>
                <Table size="small">
                  <TableHead style={{padding: 0}}>
                    <TableRow>
                      <TableCell
                        className={styles.cellFixed}
                        style={{backgroundColor: 'transparent'}}
                      />
                      {options[0] &&
                        options[0].opcoesRespostaColuna.map((option, index) => {
                          if (option.iconeCor !== '') {
                            if (useEmoji) {
                              return (
                                <TableCell key={option.id} align="center">
                                  {renderEmoji(option.iconeCor)}
                                  <Typography noWrap>
                                    {option.descricao}
                                  </Typography>
                                </TableCell>
                              );
                            }
                            return (
                              <TableCell
                                key={option.id}
                                align="center"
                                style={{
                                  paddingLeft: theme.spacing(0.5),
                                  paddingRight: theme.spacing(0.5),
                                  border: 0,
                                }}>
                                <Box
                                  component={Paper}
                                  padding={1}
                                  minWidth={65}
                                  style={{
                                    backgroundColor:
                                      option.iconeCor === '' ||
                                      option.iconeCor === null
                                        ? 'transparent !important'
                                        : option.iconeCor,
                                    color:
                                      option.iconeCor === '' ||
                                      option.iconeCor === null
                                        ? theme.palette.grey[800]
                                        : theme.palette.common.white,
                                  }}>
                                  <Typography align="center" noWrap>
                                    {option.descricao || `Opção ${index + 1}`}
                                  </Typography>
                                </Box>
                              </TableCell>
                            );
                          } else {
                            return (
                              <TableCell key={option.id} align="center">
                                <Typography noWrap>
                                  {option.descricao}
                                </Typography>
                              </TableCell>
                            );
                          }
                        })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {options.map((option, rowPosition) => {
                      const hasSelectedOptions =
                        option.opcoesRespostaColuna.some(
                          (option) => option.selecionado,
                        );
                      return (
                        <TableRow key={option.id} className={styles.tableRow}>
                          <TableCell
                            className={clsx(styles.tableCell, {
                              [styles.borderRadiusLeft]: true,
                              [styles.cellFixed]: true,
                              [styles.shadow]: true,
                            })}
                            width="auto">
                            <Typography
                              noWrap
                              color={
                                hasError && !hasSelectedOptions
                                  ? 'error'
                                  : 'initial'
                              }>
                              {option.descricao}
                            </Typography>
                          </TableCell>
                          {option.opcoesRespostaColuna.map(
                            (columnOption, columnPosition) => {
                              return (
                                <TableCell
                                  key={columnOption.id}
                                  align="center"
                                  className={clsx(styles.tableCell, {
                                    [styles.borderRadiusRight]:
                                      option.opcoesRespostaColuna.length - 1 ===
                                      columnPosition,
                                  })}>
                                  <FormControlLabel
                                    disabled={isCompleted}
                                    label=""
                                    value={columnOption.id}
                                    control={
                                      <Radio
                                        checked={columnOption.selecionado}
                                      />
                                    }
                                    labelPlacement="top"
                                    onClick={() =>
                                      handleOption(rowPosition, columnPosition)
                                    }
                                  />
                                </TableCell>
                              );
                            },
                          )}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Box>
            </Box>
          </Grid>
          {((hasComment && !isCompleted) ||
            (comment !== '' && isCompleted)) && (
            <Box marginTop={3} justifyContent="center" display="flex">
              <TextField
                fullWidth
                multiline
                rows={2}
                disabled={isCompleted}
                color="primary"
                label="Comentário"
                variant="outlined"
                placeholder="Adicionar um comentário"
                value={comment}
                onChange={handleComment}
              />
            </Box>
          )}
        </>
      );
    },
  );
